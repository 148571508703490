import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import Landing from '@/components/Landing.vue'
import Home from '@/components/Home.vue'
import Projects from '@/components/Projects.vue'
import Languages from '@/components/Languages.vue'
import Shop from '@/components/Shop.vue'
import BacklightShop from '@/components/BacklightShop.vue'
import BackgroundShop from '@/components/BackgroundShop.vue'
import Extras from '@/components/Extras.vue'
import Terminal from '@/components/Terminal.vue'
import About from '@/components/About.vue'
import Contact from '@/components/Contact.vue'
import Arcade from '@/components/Arcade.vue'
import Snake from '@/components/Snake.vue'
import Pong from '@/components/Pong.vue'
import Pacman from '@/components/Pacman.vue'
import Asteroids from '@/components/Asteroids.vue'
import Bird from '@/components/Bird.vue'
import GameShop from '@/components/GameShop.vue'
import FontShop from '@/components/FontShop.vue'
import SoundTrack from '@/components/SoundtrackShop.vue'

Vue.use(VueRouter, VueAxios, axios);

const routes = [
  { path: '/', component: Landing },
  { path: '/home', component: Home },
  { path: '/projects', component: Projects },
  { path: '/languages', component: Languages },
  { path: '/shop', component: Shop },
  { path: '/backlight-shop', component: BacklightShop },
  { path: '/background-shop', component: BackgroundShop },
  { path: '/extras', component: Extras },
  { path: '/terminal', component: Terminal },
  { path: '/about', component: About },
  { path: '/contact', component: Contact },
  { path: '/arcade', component: Arcade },
  { path: '/snake', component: Snake },
  { path: '/pong', component: Pong },
  { path: '/pacman', component: Pacman },
  { path: '/asteroids', component: Asteroids },
  { path: '/flappy-bird', component: Bird },
  { path: '/game-shop', component: GameShop },
  { path: '/font-shop', component: FontShop },
  { path: '/soundtrack-shop', component: SoundTrack }
];

const router = new VueRouter({
  routes: routes,
  mode: 'history',
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
}
});

Vue.config.productionTip = false


new Vue({
  render: h => h(App),
  router
}).$mount('#app')
