<template>
  <div
    id="app"
    :class="this.background != '' ? backgroundImage : scanlines"
    :style="[cssVars, this.background == '' ? {} : {'background-image': 'url(' + require(`@/assets/images/${this.background}.jpg`) + ')'}]"
  >
    <a
      @click="backButton"
      class="nes-badge"
    >
      <span class="is-error">BACK</span>
    </a>
    <div class="credits">
      <h3 class="credits-title">
        CREDITS
      </h3>
      <h3 class="credits-number">
        {{coins}}
      </h3>
    </div>

    <transition name="static">
      <router-view></router-view>
    </transition>
    <audio
      autoplay
      id="soundtrack"
      loop
      v-if="sound !== ''"
    >
      <source
        :src="require(`./assets/sounds/${this.sound}.ogg`)"
        type="audio/mp3"
      >
    </audio>
    <div
      class="volume-control"
      v-if="sound !== ''"
      @mouseenter="addClass"
      @mouseleave="removeClass"
    >
      <input
        type="range"
        name="volume"
        id="volume"
        min="0"
        max="1"
        step="0.1"
        v-model="volume"
        @change="changeVolume()"
      >
      <img
        class="volume-icon"
        src="./assets/images/volume.png"
        alt=""
      >
    </div>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  name: "App",
  data() {
    return {
      coins: 0,
      backlight: "transparent",
      font: "#f3eee7",
      static: "0.3",
      sound: "",
      volume: 1,
      rate: "1",
      axis: "360deg",
      background: "",
      backgroundImage: "background-image",
      scanlines: "scanlines",
    };
  },
  watch: {
    coins(newCoins) {
      localStorage.coins = JSON.stringify(newCoins);
    },
    backlight(newBacklight) {
      localStorage.backlight = JSON.stringify(newBacklight);
    },
    background(newBackground) {
      localStorage.background = JSON.stringify(newBackground);
    },
    static(newStatic) {
      localStorage.static = JSON.stringify(newStatic);
    },
    rate(newRate) {
      localStorage.rate = JSON.stringify(newRate);
    },
    sound(newSound) {
      localStorage.sound = JSON.stringify(newSound);
    },
    volume(newVolume) {
      localStorage.volume = JSON.stringify(newVolume);
    },
    axis(newAxis) {
      localStorage.axis = JSON.stringify(newAxis);
    },
  },
  computed: {
    cssVars() {
      return {
        "--spotlight-color": this.backlight,
        "--font-color": this.font,
        "--static": this.static,
        "--axis": this.axis,
      };
    },
  },
  mounted() {
    //Local storage for coin count
    if (localStorage.coins) {
      this.coins = JSON.parse(localStorage.coins);
    }
    if (localStorage.axis) {
      this.axis = JSON.parse(localStorage.axis);
    }
    if (localStorage.backlight) {
      this.backlight = JSON.parse(localStorage.backlight);
    }
    if (localStorage.font) {
      this.font = JSON.parse(localStorage.font);
    }
    if (localStorage.background) {
      this.background = JSON.parse(localStorage.background);
    }
    if (localStorage.static) {
      this.static = JSON.parse(localStorage.static);
    }
    if (localStorage.rate) {
      this.rate = JSON.parse(localStorage.rate);
    }
    if (localStorage.sound) {
      this.sound = JSON.parse(localStorage.sound);
    }
    if (localStorage.volume) {
      this.volume = JSON.parse(localStorage.volume);
    }
    //Event Listener for coin collection
    $(document).on("click", ".coins", this.collectCoins);

    //Event Listener for backspace button
    document.addEventListener("keydown", this.goBack);
    //Falling Coins logic
    function fallingSnow() {
      var $coins = $(),
        qt = Number(JSON.parse(localStorage.rate));
      for (var i = 0; i < qt; ++i) {
        var $coin = $('<a class="coins"></a>');
        $coin.css({
          left: Math.random() * $("body").width() + "px",
          top: -Math.random() * $("body").height() + "px",
        });
        // add this coin to the set of coins
        $coins = $coins.add($coin);
      }
      $("body").prepend($coins);

      $coins.animate(
        {
          top: "200%",
          opacity: "10.5",
        },
        Math.random() + 15000,
        function () {
          $(this).remove();
          if (--qt < 1) {
            setTimeout(() => {
              fallingSnow();
            }, 500);
          }
        }
      );
      $coins.on("click", function () {
        var coin = new Audio(require(`@/assets/sounds/coin.mp3`));
        $(this).remove();
        coin.volume = 0.2;
        coin.play();
      });
    }
    setTimeout(() => {
      fallingSnow();
    }, 100);
  },
  methods: {
    goBack(e) {
      if (window.location.href.indexOf("extras") > -1) {
        return;
      } else if (window.location.href.indexOf("contact") > -1) {
        return;
      } else {
        if (e.keyCode == 8) {
          this.$router.back();
        }
      }
    },
    changeVolume() {
      var soundtrackVol = document.getElementById("soundtrack");
      soundtrackVol.volume = this.volume;
    },
    backButton() {
      this.$router.back();
    },

    addClass: function (e) {
      $(".volume-control input").addClass("show");
    },
    removeClass: function (e) {
      $(".volume-control input").removeClass("show");
    },
    collectCoins() {
      this.coins++;
    },
  },
};
</script>

<style lang="scss">
#app {
  height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
  overflow: hidden;
  transform: rotate(var(--axis));
}
/* Coins */
.coins {
  content: url("./assets/images/coin.png");
  top: 0px;
  left: 0px;
  position: absolute;
  z-index: 200;
  width: 50px;
  height: 50px;
  filter: drop-shadow(1px 1px 30px rgb(212, 163, 28));
}

@media (min-width: 768px) {
  .coins {
    content: url("./assets/images/coin.png");
    top: 0px;
    left: 0px;
    position: absolute;
    z-index: 200;
    width: 50px;
    height: 50px;
    filter: drop-shadow(1px 1px 30px rgb(212, 163, 28));
  }
}

/* Fonts */
@font-face {
  font-family: "PressStart2P";
  src: local("PressStart2P"),
    url(./assets/fonts/PressStart2P-Regular.ttf) format("truetype");
}

span,
input {
  font-family: "PressStart2P" !important;
}
/* Global Styles */
body {
  background-color: #161616;
  font-family: "PressStart2P", Helvetica, Arial;
  overflow: hidden;
  z-index: 0;
  margin: 0;
}

.scanlines::after {
  content: "";
  background-image: url("./assets/images/static.gif");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: var(--static);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0px;
  z-index: 2;
}
@media (min-width: 768px) {
  .scanlines::after {
    width: 105%;
    left: -3%;
  }
}

.background-image {
  padding: 32px;
}
</style>
