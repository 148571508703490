<template>
  <div class="container">
    <div class="top-container">
      <div class="title-container">
        <h1 class="title">ABOUT ME</h1>
      </div>
    </div>
    <div class="about-me">
      <div class="nes-container is-rounded">
        <p>I am a full stack web developer with professional experience working with PHP, Javascript/Jquery, HTML, CSS/SASS and MYSQL. I have worked heavily with platforms such as Magento & Magento 2 as well as Wordpress and Shopify. For the past few years I have taken a huge interest in Javascript frameworks such as React and Vue and have built many personal projects with the MERN & MEVN Stack.</p>
      </div>

      <div class="socials-container">
        <!-- instagram -->
        <a
          href=""
          @mouseenter="addClass"
          @mouseleave="removeClass"
        ><i class="nes-icon instagram is-large"></i></a>

        <!-- github -->
        <a
          href="https://github.com/NathanDarlow/"
          target="_blank"
          @mouseenter="addClass"
          @mouseleave="removeClass"
        ><i class="nes-icon github is-large"></i></a>

        <!-- linkedin -->
        <a
          href="www.linkedin.com/in/nathan-darlow-5465a2150"
          target="_blank"
          @mouseenter="addClass"
          @mouseleave="removeClass"
        ><i class="nes-icon linkedin is-large"></i></a>

        <!-- whatsapp -->
        <a
          href="https://wa.me/07849072964"
          target="_blank"
          @mouseenter="addClass"
          @mouseleave="removeClass"
        ><i class="nes-icon whatsapp is-large"></i></a>

      </div>

      <div class="cta-button">
        <router-link to="/contact"><button
            type="button"
            class="nes-btn is-warning"
          >CONTACT ME</button></router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    addClass: function (e) {
      e.target.classList.add("active");
    },
    removeClass: function (e) {
      e.target.classList.remove("active");
    },
  },
};
</script>