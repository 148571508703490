<template>
  <div class="container">
    <div class="top-container">
      <div class="title-container">
        <h1 class="title">SELECT SOUNDTRACK</h1>
      </div>
    </div>

    <div class="options-container soundtracks">
      <ul class="card-list">
        <li
          v-for='sound in sounds'
          :key='sound.id'
          :class='{"active-item": currentItem === sound.id}'
          @mouseenter="addClass"
          @mouseleave="removeClass"
        >
          <div
            class="option"
            @click="buyItem(sound.price, sound.link)"
          >
            <img
              src="@/assets/images/select.png"
              class="left-arrow"
              alt=""
            >
            <audio>
              <source
                :src="require(`@/assets/sounds/${sound.link}.ogg`)"
                type="audio/mp3"
              >
            </audio>
            <span>{{sound.text}} X {{sound.price}}</span>
            <img
              src="@/assets/images/select.png"
              class="right-arrow"
              alt=""
            >
          </div>
        </li>
      </ul>
    </div>

    <div
      class="error-message"
      :class='{"active": errorMessage == true}'
    >
      YOU DO NOT HAVE ENOUGH COINS
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  data() {
    return {
      currentItem: 1,
      sounds: [
        { id: 1, text: "RUINED PLANET", link: "ruined-planet", price: 20 },
        {
          id: 2,
          text: "HEROIC INTRUSION",
          link: "heroic-intrusion",
          price: 25,
        },
        {
          id: 3,
          text: "INTERSTELLER ODYSSEY",
          link: "interstellar-odyssey",
          price: 25,
        },
        {
          id: 4,
          text: "VOICES FROM HEAVEN",
          link: "voices-from-heaven",
          price: 30,
        },
        {
          id: 5,
          text: "GREAT LITTLE CHALLENGE",
          link: "great-little-challenge",
          price: 50,
        },
      ],
      sound: "",
      coins: 0,
      errorMessage: false,
    };
  },
  watch: {
    coins(newCoins) {
      localStorage.coins = JSON.stringify(newCoins);
    },
    sound(newSounds) {
      localStorage.sound = JSON.stringify(newSounds);
    },
  },
  mounted() {
    document.addEventListener("keydown", this.nextItem);

    //Local storage for coin count
    if (localStorage.coins) {
      this.coins = JSON.parse(localStorage.coins);
    }
    if (localStorage.sound) {
      this.sound = JSON.parse(localStorage.sound);
    }
  },
  methods: {
    nextItem(e) {
      if (e.keyCode == 38 && this.currentItem > 1) {
        this.currentItem--;
      } else if (e.keyCode == 40 && this.currentItem < 6) {
        this.currentItem++;
      }
    },
    addClass: function (e) {
      e.target.classList.add("active-item");
    },
    removeClass: function (e) {
      e.target.classList.remove("active-item");
    },
    buyItem: function (price, link) {
      if (this.coins < price) {
        this.errorMessage = !this.errorMessage;
        setTimeout(() => {
          this.errorMessage = false;
        }, 3000);
      } else {
        this.coins -= price;
        this.sound = link;
        this.$router.go();
      }
    },
  },
};
</script>
<style lang="scss">
.error-message {
  justify-content: flex-end;
  color: red;
  width: 100%;
  text-align: center;
  margin: auto;
  font-size: 30px;
  line-height: 30px;
  letter-spacing: 5px;
  text-shadow: 1px 1px white;
  display: none;

  &.active {
    display: block !important;
  }
}

.soundtracks {
  ul {
    width: 100% !important;
  }
}
</style>