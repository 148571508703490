<template>
  <div class="container">
    <div class="top-container">
      <div class="title-container">
        <h1 class="title">SETTINGS</h1>
      </div>
    </div>

    <div class="options-container">
      <ul
        class="card-list"
        style="width: 75%"
      >
        <div
          class="nes-container is-rounded is-dark"
          style="padding: 10px"
        >
          <!-- Coin Settins -->
          <div class="coin-settings">
            <span>Coin Rate: </span>
            <label>
              <input
                type="checkbox"
                class="nes-checkbox is-dark"
                name="normal"
                checked
                v-on:change="changeRate('1')"
              />
              <span>Normal</span>
            </label>

            <label>
              <input
                type="checkbox"
                name="medium"
                class="nes-checkbox is-dark"
                v-on:change="changeRate('25')"
              />
              <span>Medium</span>
            </label>

            <label>
              <input
                type="checkbox"
                name="insane"
                class="nes-checkbox is-dark"
                v-on:change="changeRate('50')"
              />
              <span>Insane</span>
            </label>
          </div>

          <!-- Static Settings -->
          <div class="static-intensity">
            <span>Static Intensity: </span>
            <label>
              <input
                type="checkbox"
                value="normal"
                class="nes-checkbox is-dark normal"
                name="50%"
                checked
                true-value="normal"
                v-on:change="changeStatic('30%')"
              />
              <span>Normal</span>
            </label>

            <label>
              <input
                type="checkbox"
                value="medium"
                name="static-medium"
                class="nes-checkbox is-dark medium"
                true-value="medium"
                v-on:change="changeStatic('50%')"
              />
              <span>Medium</span>
            </label>

            <label>
              <input
                type="checkbox"
                value="high"
                name="static-high"
                class="nes-checkbox is-dark high"
                true-value="high"
                v-on:change="changeStatic('70%')"
              />
              <span>High</span>
            </label>
          </div>
          <!-- Terminal -->
          <div class="buttons">
            <button
              type="button"
              class="nes-btn is-warning"
              onclick="document.getElementById('dialog-dark-rounded').showModal();"
            >
              Open Terminal
            </button>
            <button
              type="button"
              class="nes-btn is-error"
              @click="this.resetSite"
            >
              Reset Site
            </button>
            <button
              type="button"
              class="nes-btn is-success"
              @click="this.saveSettings"
            >
              Save
            </button>
          </div>
          <dialog
            class="nes-dialog is-dark is-rounded"
            id="dialog-dark-rounded"
          >
            <div method="dialog">
              <div class="terminal-container">
                <Terminal />
              </div>
              <form>
                <button class="nes-btn is-error">Cancel</button>
              </form>
            </div>
          </dialog>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Terminal from "./Terminal.vue";
export default {
  components: {
    Terminal,
  },
  data() {
    return {
      currentItem: 1,
      static: "",
      staticChecked: "1",
      rate: "1",
    };
  },
  watch: {
    static(newStatic) {
      localStorage.static = JSON.stringify(newStatic);
    },
    rate(newRate) {
      localStorage.rate = JSON.stringify(newRate);
    },
  },
  mounted() {
    document.addEventListener("keydown", this.nextItem);
    $(".coin-settings").on("click", 'input[type="checkbox"]', function () {
      $('.coin-settings input[type="checkbox"]')
        .not(this)
        .prop("checked", false);
    });
    $(".static-intensity").on("click", 'input[type="checkbox"]', function () {
      $('.static-intensity input[type="checkbox"]')
        .not(this)
        .prop("checked", false);
    });

    if (localStorage.static) {
      this.static = JSON.parse(localStorage.static);
    }
    if (localStorage.rate) {
      this.rate = JSON.parse(localStorage.rate);
    }
  },
  methods: {
    nextItem(e) {
      if (e.keyCode == 38 && this.currentItem > 1) {
        this.currentItem--;
      } else if (e.keyCode == 40 && this.currentItem < 6) {
        this.currentItem++;
      }
      if (e.keyCode == 13) {
        document.getElementById(this.currentItem).click();
      }
    },
    addClass: function (e) {
      e.target.classList.add("active-item");
    },
    removeClass: function (e) {
      e.target.classList.remove("active-item");
    },
    changeStatic: function (value) {
      this.static = value;
    },
    changeRate: function (value) {
      this.rate = value;
    },
    saveSettings: function () {
      this.$router.go();
    },
    resetSite: function () {
      localStorage.clear();
      this.$router.go();
    },
  },
};
</script>


<style scoped>
form {
  flex-direction: column !important;
  display: flex;
  width: 25%;
  margin: auto;
}
</style>