<template>
  <div class="container">
    <div id="pacman"></div>
  </div>
</template>


<script>
export default {
  mounted() {
    if (!localStorage.pacman) {
      this.$router.push("/game-shop");
    }
  },
};
</script>