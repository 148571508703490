<template>
  <div class="container">
    <div class="top-container">
      <div class="title-container">
        <h1 class="title">SELECT GAME</h1>
      </div>
    </div>

    <div class="options-container games">
      <ul class="card-list">
        <li
          v-for='game in games'
          :key='game.id'
          :class='{"active-item": currentItem === game.id}'
          @mouseenter="addClass"
          @mouseleave="removeClass"
        >
          <div
            class="option"
            @click="buyItem(game.price, game.text)"
          >
            <img
              src="@/assets/images/select.png"
              class="left-arrow"
              alt=""
            >
            <span>{{game.text}} X {{game.price}}</span>
            <img
              src="@/assets/images/select.png"
              class="right-arrow"
              alt=""
            >
          </div>
        </li>
      </ul>
    </div>

    <div
      class="error-message"
      :class='{"active": errorMessage == true}'
    >
      YOU DO NOT HAVE ENOUGH COINS
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentItem: 1,
      games: [
        { id: 1, text: "PONG", price: 20 },
        { id: 2, text: "ASTEROIDS", price: 25 },
        { id: 3, text: "PACMAN", price: 30 },
        {
          id: 4,
          text: "SPACE-INVADERS",
          price: 35,
        },
        {
          id: 5,
          text: "FLAPPY-BIRD",
          price: 40,
        },
      ],
      game: [],
      coins: 0,
      errorMessage: false,
    };
  },
  watch: {
    coins(newCoins) {
      localStorage.coins = JSON.stringify(newCoins);
    },
    game(newGame) {
      if (newGame == "PONG") {
        localStorage.pong = JSON.stringify("true");
      }
      if (newGame == "ASTEROIDS") {
        localStorage.asteroids = JSON.stringify("true");
      }
      if (newGame == "PACMAN") {
        localStorage.pacman = JSON.stringify("true");
      }
      if (newGame == "SPACE-INVADERS") {
        localStorage.invaders = JSON.stringify("true");
      }
      if (newGame == "FLAPPY-BIRD") {
        localStorage.bird = JSON.stringify("true");
      }
    },
  },
  mounted() {
    document.addEventListener("keydown", this.nextItem);

    //Local storage for coin count
    if (localStorage.coins) {
      this.coins = JSON.parse(localStorage.coins);
    }
    if (localStorage.game) {
      this.game = JSON.parse(localStorage.game);
    }
  },
  methods: {
    nextItem(e) {
      if (e.keyCode == 38 && this.currentItem > 1) {
        this.currentItem--;
      } else if (e.keyCode == 40 && this.currentItem < 6) {
        this.currentItem++;
      }
    },
    addClass: function (e) {
      e.target.classList.add("active-item");
    },
    removeClass: function (e) {
      e.target.classList.remove("active-item");
    },
    buyItem: function (price, id) {
      if (this.coins < price) {
        this.errorMessage = !this.errorMessage;
        setTimeout(() => {
          this.errorMessage = false;
        }, 3000);
      } else {
        this.coins -= price;
        this.game = id;
        this.$router.go("/arcade");
      }
    },
  },
};
</script>
<style lang="scss">
.error-message {
  justify-content: flex-end;
  color: red;
  width: 100%;
  text-align: center;
  margin: auto;
  font-size: 30px;
  line-height: 30px;
  letter-spacing: 5px;
  text-shadow: 1px 1px white;
  display: none;

  &.active {
    display: block !important;
  }
}

.games {
  ul {
    width: 100% !important;
  }
}
</style>