<template>
  <a
    class="container"
    id="link"
    href="/home"
  >
    <div class="top-container">
      <div class="high-score">
        <h2 class="score-title">
          HI-SCORE
        </h2>
        <h2 class="score-number">
          {{totalHits}}
        </h2>
      </div>
    </div>
    <div class="page-title-container">
      <h1 class="page-title">
        WELCOME
      </h1>
    </div>

    <div class="cta-desktop">PRESS ANY BUTTON TO CONTINUE</div>
    <div class="cta-mobile">TAP ANYWHERE TO CONTINUE</div>
  </a>
</template>

<script>
import $ from "jquery";
import axios from "axios";
export default {
  data() {
    return {
      hits: 1,
      totalHits: 0,
      uid: "",
      rate: "1",
    };
  },
  created() {
    window.addEventListener("keypress", logKey);
    function logKey() {
      document.getElementById("link").click();
    }
  },
  watch: {
    uid(newUid) {
      localStorage.uid = JSON.stringify(newUid);
    },

    rate() {
      localStorage.rate = JSON.stringify(this.rate);
    },
  },
  mounted() {
    localStorage.rate = JSON.stringify(this.rate);
    this.allHits();

    $(".nes-badge").remove();
    if (!localStorage.uid) {
      this.uid =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);
      setTimeout(() => {
        this.uid = JSON.parse(localStorage.uid);
      }, 10000);
    }
    if (localStorage.uid) {
      return;
    } else {
      axios
        .post(
          "https://portfolio-a034a-default-rtdb.europe-west1.firebasedatabase.app/hits.json",
          {
            hits: this.hits,
          }
        )
        .then(function (data) {
          console.log(data);
        });
    }
  },

  methods: {
    allHits() {
      axios
        .get(
          "https://portfolio-a034a-default-rtdb.europe-west1.firebasedatabase.app/hits.json"
        )
        .then(function (data) {
          return data.data;
        })
        .then((data) => {
          var countHits = [];
          for (let key in data) {
            countHits.push(data[key]);
          }
          this.totalHits = countHits.length;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#link {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

span {
  display: none !important;
}

.high-score {
  justify-content: flex-start;
  text-align: center;
  font-size: 8px;
  line-height: 9px;
  letter-spacing: 5px;
  text-shadow: -2px 0 0 #fdff2a, -4px 0 0 #df4a42, 2px 0 0 #91fcfe,
    4px 0 0 #4405fc;
  .score-title {
    color: #f7d51d;
    margin: 10px;
  }

  .score-number {
    color: #f3eee7;
  }
}

@media (min-width: 768px) {
  .high-score {
    margin: auto;
    justify-content: center;
    text-align: center;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 5px;
    text-shadow: -2px 0 0 #fdff2a, -4px 0 0 #df4a42, 2px 0 0 #91fcfe,
      4px 0 0 #4405fc;
  }
}

@media (min-width: 768px) {
  .score-number {
    color: #f3eee7;
    margin-top: 20px;
  }
}

.page-title-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 64px;

  .page-title {
    color: #f3eee7;
    font-size: 30px;
    margin-top: 128px;
    line-height: 30px;
    letter-spacing: 5px;
    text-shadow: -2px 0 0 #fdff2a, -4px 0 0 #df4a42, 2px 0 0 #91fcfe,
      4px 0 0 #4405fc;
  }
  @media (min-width: 768px) {
    .page-title {
      color: #f3eee7;
      font-size: 80px;
      margin-top: 128px;
      line-height: 80px;
      letter-spacing: 5px;
      text-shadow: -2px 0 0 #fdff2a, -4px 0 0 #df4a42, 2px 0 0 #91fcfe,
        4px 0 0 #4405fc;
    }
  }
}

@media (min-width: 768px) {
  .page-title-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 128px;
  }
}

.cta-mobile {
  justify-content: flex-end;
  color: #f3eee7;
  width: 100%;
  text-align: center;
  margin: auto;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 5px;
  text-shadow: -2px 0 0 #fdff2a, -4px 0 0 #df4a42, 2px 0 0 #91fcfe,
    4px 0 0 #4405fc;
  -webkit-animation: blink-data-v-98feeed2 1s steps(4, start) infinite;
  animation: blink-data-v-98feeed2 1s steps(4, start) infinite;
}

@media (min-width: 768px) {
  .cta-mobile {
    display: none;
  }
}

.cta-desktop {
  display: none;
}

@media (min-width: 768px) {
  .cta-desktop {
    display: block;
    justify-content: flex-end;
    color: #f3eee7;
    width: 100%;
    text-align: center;
    margin: auto;
    font-size: 40px;
    line-height: 40px;
    letter-spacing: 5px;
    text-shadow: -2px 0 0 #fdff2a, -4px 0 0 #df4a42, 2px 0 0 #91fcfe,
      4px 0 0 #4405fc;
    animation: blink 1s steps(4, start) infinite;
  }
}
@-moz-keyframes blink {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink {
  to {
    visibility: hidden;
  }
}
@-o-keyframes blink {
  to {
    visibility: hidden;
  }
}
@keyframes blink {
  to {
    visibility: hidden;
  }
}
</style>
