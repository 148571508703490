<template>
  <div class="container">
    <div class="top-container">
      <div class="title-container">
        <h1 class="title">ASTEROIDS</h1>
      </div>
    </div>

    <div class="score-container">
      <div id="bestScore"></div>
      <div id="currentScore"></div>
    </div>
    <canvas
      id="canvas"
      width="431"
      height="768"
    ></canvas>
  </div>
</template>

<script>
export default {
  mounted() {
    if (!localStorage.bird) {
      this.$router.push("/game-shop");
    }
    const canvas = document.getElementById("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.src = "https://i.ibb.co/Q9yv5Jk/flappy-bird-set.png";

    // general settings
    let gamePlaying = false;
    const gravity = 0.5;
    const speed = 6.2;
    const size = [51, 36];
    const jump = -11.5;
    const cTenth = canvas.width / 10;

    let index = 0,
      bestScore = 0,
      flight,
      flyHeight,
      currentScore,
      pipes;

    // pipe settings
    const pipeWidth = 78;
    const pipeGap = 270;
    const pipeLoc = () =>
      Math.random() * (canvas.height - (pipeGap + pipeWidth) - pipeWidth) +
      pipeWidth;

    const setup = () => {
      currentScore = 0;
      flight = jump;
      // set initial flyHeight (middle of screen - size of the bird)
      flyHeight = canvas.height / 2 - size[1] / 2;

      // setup first 3 pipes
      pipes = Array(3)
        .fill()
        .map((a, i) => [canvas.width + i * (pipeGap + pipeWidth), pipeLoc()]);
    };

    const render = () => {
      // make the pipe and bird moving
      index++;

      // ctx.clearRect(0, 0, canvas.width, canvas.height);

      // background first part
      ctx.drawImage(
        img,
        0,
        0,
        canvas.width,
        canvas.height,
        -((index * (speed / 2)) % canvas.width) + canvas.width,
        0,
        canvas.width,
        canvas.height
      );
      // background second part
      ctx.drawImage(
        img,
        0,
        0,
        canvas.width,
        canvas.height,
        -(index * (speed / 2)) % canvas.width,
        0,
        canvas.width,
        canvas.height
      );

      // pipe display
      if (gamePlaying) {
        pipes.map((pipe) => {
          // pipe moving
          pipe[0] -= speed;

          // top pipe
          ctx.drawImage(
            img,
            432,
            588 - pipe[1],
            pipeWidth,
            pipe[1],
            pipe[0],
            0,
            pipeWidth,
            pipe[1]
          );
          // bottom pipe
          ctx.drawImage(
            img,
            432 + pipeWidth,
            108,
            pipeWidth,
            canvas.height - pipe[1] + pipeGap,
            pipe[0],
            pipe[1] + pipeGap,
            pipeWidth,
            canvas.height - pipe[1] + pipeGap
          );

          // give 1 point & create new pipe
          if (pipe[0] <= -pipeWidth) {
            currentScore++;
            // check if it's the best score
            bestScore = Math.max(bestScore, currentScore);

            // remove & create new pipe
            pipes = [
              ...pipes.slice(1),
              [pipes[pipes.length - 1][0] + pipeGap + pipeWidth, pipeLoc()],
            ];
            console.log(pipes);
          }

          // if hit the pipe, end
          if (
            [
              pipe[0] <= cTenth + size[0],
              pipe[0] + pipeWidth >= cTenth,
              pipe[1] > flyHeight || pipe[1] + pipeGap < flyHeight + size[1],
            ].every((elem) => elem)
          ) {
            gamePlaying = false;
            setup();
          }
        });
      }
      // draw bird
      if (gamePlaying) {
        ctx.drawImage(
          img,
          432,
          Math.floor((index % 9) / 3) * size[1],
          ...size,
          cTenth,
          flyHeight,
          ...size
        );
        flight += gravity;
        flyHeight = Math.min(flyHeight + flight, canvas.height - size[1]);
      } else {
        ctx.drawImage(
          img,
          432,
          Math.floor((index % 9) / 3) * size[1],
          ...size,
          canvas.width / 2 - size[0] / 2,
          flyHeight,
          ...size
        );
        flyHeight = canvas.height / 2 - size[1] / 2;
        // text accueil
        ctx.fillText(`Best score : ${bestScore}`, 85, 245);
        ctx.fillText("Click to play", 90, 535);
        ctx.font = "bold 20px PressStart2P";
      }

      document.getElementById("bestScore").innerHTML = `Best : ${bestScore}`;
      document.getElementById(
        "currentScore"
      ).innerHTML = `Current : ${currentScore}`;

      // tell the browser to perform anim
      window.requestAnimationFrame(render);
    };

    // launch setup
    setup();
    img.onload = render;

    // start game
    document.addEventListener("click", () => (gamePlaying = true));
    window.onclick = () => (flight = jump);
  },
};
</script>

<style>
canvas {
  display: block !important;
  margin: auto;
}
#g-game {
  position: absolute;
  top: 48px;
  left: 0;
  width: 100%;
  height: 500px;
}
</style>