<template>
  <div class="container">
    <div class="top-container">
      <div class="title-container">
        <h1 class="home-title">NATHAN DARLOW</h1>
        <div class="subtitle-container">
          <img
            src="@/assets/images/torch.gif"
            alt=""
          >
          <h2 class="subtitle">PORTFOLIO EDITION</h2>
          <img
            src="@/assets/images/torch.gif"
            alt=""
          >
        </div>
      </div>
    </div>
    <div class="options-container">
      <ul class="card-list">
        <li
          v-for='item in items'
          :key='item.id'
          :class='{"active-item": currentItem === item.id}'
          @mouseenter="addClass"
          @mouseleave="removeClass"
          @keyup.enter="enterClicked"
        >
          <router-link
            :to="item.link"
            class="option"
            :id="item.uni"
          ><img
              src="@/assets/images/select.png"
              class="left-arrow"
              alt=""
            >
            <span>{{item.text}}</span>
            <img
              src="@/assets/images/select.png"
              class="right-arrow"
              alt=""
            >
          </router-link>
        </li>
        <li class="project">
          <router-link
            to="/home"
            class="option"
          >
            <span style="opacity: 30%">PROJECTS <div class="badge">Coming soon</div></span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentItem: 1,
      items: [
        { id: 1, text: "LANGUAGES", link: "/languages", uni: "languages" },
        { id: 2, text: "ABOUT ME", link: "/about", uni: "about" },
        { id: 3, text: "THE ARCADE", link: "/arcade", uni: "arcade" },
        { id: 4, text: "SHOP", link: "/shop", uni: "shop" },
        { id: 5, text: "SETTINGS", link: "/extras", uni: "extras" },
        // { id: 6, text: "PROJECTS", link: "/projects", uni: "projects" },
      ],
    };
  },
  mounted() {
    document.addEventListener("keydown", this.nextItem);
  },
  methods: {
    nextItem(e) {
      if (e.keyCode == 38 && this.currentItem > 1) {
        this.currentItem--;
      } else if (e.keyCode == 40 && this.currentItem < 5) {
        this.currentItem++;
      }
      if (e.keyCode == 13) {
        if (this.currentItem == 1) {
          document.getElementById("projects").click();
        }

        if (this.currentItem == 2) {
          document.getElementById("about").click();
        }

        if (this.currentItem == 3) {
          document.getElementById("extras").click();
        }

        if (this.currentItem == 4) {
          document.getElementById("shop").click();
        }

        if (this.currentItem == 5) {
          document.getElementById("contact").click();
        }
      }
    },
    addClass: function (e) {
      e.target.classList.add("active-item");
    },
    removeClass: function (e) {
      e.target.classList.remove("active-item");
    },
  },
};
</script>
<style lang="scss" scoped>
#arcade {
  display: none;
}
@media (min-width: 768px) {
  #arcade {
    display: flex;
  }
}

.project {
  span {
    position: relative;
    opacity: 35%;
    .badge {
      position: absolute;
      top: 0;
      right: -5rem;
      font-size: 16px;
      color: #f7d51d;
    }
  }
}
</style>