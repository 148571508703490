<template>
  <div class="container">
    <div class="top-container">
      <div class="title-container">
        <h1 class="title">SPEND YOUR COINS</h1>
      </div>
    </div>

    <div class="options-container">
      <ul class="card-list">
        <li
          v-for='shop in shops'
          :key='shop.id'
          :class='{"active-item": currentItem === shop.id}'
          @mouseenter="addClass"
          @mouseleave="removeClass"
        >
          <router-link
            class="option"
            :id="shop.text.toLowerCase()"
            :to="shop.link"
          >
            <img
              src="@/assets/images/select.png"
              class="left-arrow"
              alt=""
            >
            <span>{{shop.text}}</span>
            <img
              src="@/assets/images/select.png"
              class="right-arrow"
              alt=""
            >
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentItem: 1,
      shops: [
        { id: 1, text: "BACKLIGHTS", link: "/backlight-shop" },
        { id: 2, text: "BACKGROUNDS", link: "/background-shop" },
        { id: 3, text: "FONT COLOURS", link: "/font-shop" },
        { id: 4, text: "GAMES", link: "/game-shop" },
        { id: 5, text: "SOUNDTRACKS", link: "/soundtrack-shop" },
      ],
    };
  },
  mounted() {
    document.addEventListener("keydown", this.nextItem);
  },
  methods: {
    nextItem(e) {
      if (e.keyCode == 38 && this.currentItem > 1) {
        this.currentItem--;
      } else if (e.keyCode == 40 && this.currentItem < 6) {
        this.currentItem++;
      }
      if (e.keyCode == 13) {
        document.getElementById(this.currentItem).click();
      }
    },
    addClass: function (e) {
      e.target.classList.add("active-item");
    },
    removeClass: function (e) {
      e.target.classList.remove("active-item");
    },
  },
};
</script>


<style scoped>
#games {
  display: none;
}
@media (min-width: 768px) {
  #games {
    display: flex;
  }
}
</style>