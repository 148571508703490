<template>
  <div class="wrapper">
    <div class="top-container">
      <div class="title-container">
        <h1 class="title">TERMINAL</h1>
      </div>
    </div>
    <div class="terminal-container">
      <vue-command
        :commands="commands"
        id="terminal"
        title="Nathan@Darlow: ~"
        prompt="~nathan@darlow:#"
        show-help
        help-text="Type Help"
        history
      />
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import VueCommand, { createStdout } from "vue-command";
import "vue-command/dist/vue-command.css";
export default {
  components: {
    VueCommand,
  },
  data: () => ({
    coins: 0,
    axis: "180deg",
    commands: {
      help: () =>
        createStdout(
          "Available commands: <br><br><br> motherload <br><br> australia <br><br>"
        ),
      "isobels-a-bitch": () => createStdout("Youre right"),
      "ginas-a-bitch": () => createStdout("Yepp"),
      motherload: () => {
        localStorage.setItem("coins", "999");
        location.reload();
      },
      australia: () => {
        localStorage.axis = JSON.stringify("180deg");
        $("#app").addClass("rotate");
      },
    },
  }),
  watch: {
    coins(newCoins) {
      localStorage.coins = JSON.stringify(newCoins);
    },
  },
  mounted() {
    if (localStorage.coins) {
      this.coins = JSON.parse(localStorage.coins);
    }
  },
  methods: {},
};
</script>
<style lang="scss">
.wrapper {
  position: relative;
  z-index: 99;
  display: flex;
  flex-direction: column;

  .terminal-container {
    max-height: 700px;
    margin: 50px auto;
    min-width: 700px;
  }

  .no-show {
    display: none;
  }
}
.vue-command .term-stdout {
  word-break: break-all;
  text-align: left;
  display: flex;
  margin-bottom: 25px;
}
</style>