<template>
    <section class="background-shop">
        <div class="container">
        <div class="top-container">
            <div class="title-container">
                <h1 class="title">SELECT IMAGE</h1>
            </div>
        </div>

        <div class="table-container">
            <div class="item" v-for='background in backgrounds' :key='background.id'  :class='{"active-item": currentItem === background.id}' @mouseenter="addClass" @mouseleave="removeClass"  @click="buyItem(background.price, background.image)">
                <img :src="require(`@/assets/images/${background.smallImage}.jpg`)">
                <h2 class="overlay-text">
                    X {{background.price}}
                </h2>
            </div>
        </div>
    </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            currentItem: 1,
            backgrounds: [
                {id: 1, image: "background-1", smallImage: "background-1-small", price: 10}, 
                {id: 2, image: "background-2", smallImage: "background-2-small", price: 15},
                {id: 3, image: "background-3", smallImage: "background-3-small", price: 20},
            ],
            background: "",
            coins: 0,
            errorMessage: false
        }
    },
    watch:{
        coins(newCoins) {
            localStorage.coins = JSON.stringify(newCoins);
        },
        background(newBackground) {
            localStorage.background = JSON.stringify(newBackground)
        }
    },
    mounted () {
        document.addEventListener("keydown", this.nextItem);

        //Local storage for coin count
        if(localStorage.coins) {
            this.coins = JSON.parse(localStorage.coins);
        }
        if(localStorage.background) {
            this.background = JSON.parse(localStorage.background);
        }
    },
    methods: {
    nextItem (e) {
        if (e.keyCode == 37 && this.currentItem > 1) {
            this.currentItem--
      } else if (e.keyCode == 39 && this.currentItem < 5) {
            this.currentItem++
      }
      if (e.keyCode == 38 && this.currentItem > 1) {
            this.currentItem-= 3
      } else if (e.keyCode == 40 && this.currentItem < 5) {
            this.currentItem+= 3
      }
        if (e.keyCode == 13) {
            document.getElementById(this.currentItem).click();
        }
    },
    addClass: function(e) {
        e.target.classList.add("active-item")
    },
    removeClass: function(e) {
        e.target.classList.remove("active-item")
    },
    buyItem: function(price, image) {
        if(this.coins < price) {
            this.errorMessage = !this.errorMessage;
            setTimeout(()=>{
                this.errorMessage = false;
            }, 3000 );
        }
        else {
            this.coins -= price;
            this.background = image;
            this.$router.go();
        }
    }
  }
}
</script>

<style lang="scss">
    .background-shop {
        .table-container{
    display: flex;
    margin: 0 auto;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 12px;

    .item {
        list-style: none;
        width: 50%;
        display: flex;
        justify-content: center;


        img{
            margin: 5px;
            height: 65px;
            width: 100px;
        }
        @media (min-width: 768px) {
            img{
                margin: 10px;
                height: 200px; 
                width: 200px
            }
        }
        h2{
            color: white;
        }

        .overlay-text{
            display: none;
        }
        &.active-item {
        animation: flash 300ms linear; 
        
        .overlay-text{
            display: block!important;
            position: absolute;
            color: red;
        }
        img{
            filter: drop-shadow(1px 1px 30px rgb(224, 220, 209));
        }
    }
    }
    @media (min-width: 768px) {
        .item {
            list-style: none;
            width: 33%;
            display: flex;
            justify-content: center;
        }
    }
    
}
    }
</style>