<template>
  <div class="container">
    <div class="top-container">
      <div class="title-container">
        <h1 class="title">CHOOSE YOUR LANGUAGE</h1>
      </div>
    </div>
    <div class="languages">
      <div class="table-container">
        <div
          class="item"
          v-for='project in projects'
          :key='project.id'
          :class='{"active-item": currentItem === project.id}'
          @mouseenter="addClass(project.id)"
          @mouseleave="removeClass"
        >
          <img :src="require(`@/assets/images/${project.image}.png`)">
          <h2 class="overlay-text">
            1P
          </h2>
        </div>
      </div>
      <div class="nes-container is-rounded">
        <div
          v-for='project in projects'
          :key='project.id'
          :class='{"active-item": currentItem === project.id}'
        >
          <div class="image">
            <img
              :src="require(`@/assets/images/${project.image}.png`)"
              alt=""
            >
          </div>
          <div
            class="option"
            :id="projects.id"
            v-if="currentItem === project.id"
          >
            <div class="content-wrapper">
              <h2 style="color: black; text-align: center">{{project.title}}</h2>
              <div class="skill">
                <div class="progress-container">

                  <div class="progress">
                    <p>Fluency:</p>
                    <progress
                      class="nes-progress is-error"
                      :value="project.skill"
                      max="100"
                    ></progress>
                  </div>

                  <div class="progress">
                    <p>Experience:</p>
                    <progress
                      class="nes-progress is-warning"
                      :value="project.xp"
                      max="100"
                    ></progress>
                  </div>

                  <div class="progress">
                    <p>Commercial XP:</p>
                    <progress
                      class="nes-progress is-primary"
                      :value="project.cxp"
                      max="100"
                    ></progress>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentItem: 1,
      projects: [
        {
          id: 1,
          image: "html",
          title: "HTML",
          skill: "100",
          xp: "100",
          cxp: "100",
        },
        {
          id: 2,
          image: "css",
          title: "CSS",
          skill: "100",
          xp: "100",
          cxp: "100",
        },
        {
          id: 3,
          image: "sass",
          title: "SASS",
          skill: "90",
          xp: "85",
          cxp: "75",
        },
        {
          id: 4,
          image: "less",
          title: "Less",
          skill: "70",
          xp: "60",
          cxp: "60",
        },
        {
          id: 5,
          image: "javascript",
          title: "Javascript",
          skill: "90",
          xp: "85",
          cxp: "85",
        },
        {
          id: 6,
          image: "jquery",
          title: "Jquery",
          skill: "80",
          xp: "75",
          cxp: "70",
        },
        {
          id: 7,
          image: "vuejs",
          title: "VueJs",
          skill: "85",
          xp: "70",
          cxp: "50",
        },
        {
          id: 8,
          image: "react",
          title: "ReactJS",
          skill: "65",
          xp: "50",
          cxp: "35",
        },
        { id: 9, image: "php", title: "PHP", skill: "65", xp: "80", cxp: "80" },
        {
          id: 10,
          image: "nodejs",
          title: "Node JS",
          skill: "40",
          xp: "35",
          cxp: "15",
        },
        {
          id: 11,
          image: "python",
          title: "Python",
          skill: "70",
          xp: "15",
          cxp: "10",
        },
        {
          id: 12,
          image: "xd",
          title: "Adobe XD",
          skill: "40",
          xp: "60",
          cxp: "50",
        },
        {
          id: 13,
          image: "illustrator",
          title: "Adobe Illustrator",
          skill: "25",
          xp: "10",
          cxp: "10",
        },
        {
          id: 14,
          image: "photoshop",
          title: "Adobe Photoshop",
          skill: "25",
          xp: "10",
          cxp: "10",
        },
      ],
    };
  },
  mounted() {
    document.addEventListener("keydown", this.nextItem);
  },
  methods: {
    nextItem(e) {
      if (e.keyCode == 37 && this.currentItem > 1) {
        this.currentItem--;
      } else if (e.keyCode == 39 && this.currentItem < 14) {
        this.currentItem++;
      }
      if (e.keyCode == 38 && this.currentItem > 4) {
        this.currentItem -= 4;
      }
      if (e.keyCode == 40 && this.currentItem == 10) {
        this.currentItem += 4;
      }
      if (e.keyCode == 40 && this.currentItem == 12) {
        this.currentItem++;
      }
      if (e.keyCode == 40 && this.currentItem == 13) {
        this.currentItem;
      } else if (e.keyCode == 40 && this.currentItem < 10) {
        this.currentItem += 4;
      }
    },
    addClass: function (id, e) {
      this.currentItem = id;
      e.target.classList.add("active-item");
    },
    removeClass: function (e) {
      e.target.classList.remove("active-item");
    },
  },
};
</script>