<template>
  <div class="container">
    <div class="top-container">
      <div class="title-container">
        <h1 class="title">THE ARCADE</h1>
      </div>
    </div>

    <div class="options-container arcade">
      <ul class="card-list">
        <li
          v-for='arcade in arcades'
          :key='arcade.id'
          :class='{"active-item": currentItem === arcade.id}'
          @mouseenter="addClass"
          @mouseleave="removeClass"
        >
          <router-link
            class="option"
            :id="arcade.text.toLowerCase()"
            :to="arcade.link"
          >
            <img
              src="@/assets/images/select.png"
              class="left-arrow"
              alt=""
            >
            <span>{{arcade.text}}</span>
            <img
              src="@/assets/images/select.png"
              class="right-arrow"
              alt=""
            >
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  data() {
    return {
      currentItem: 1,
      arcades: [
        { id: 1, text: "SNAKE", link: "/snake" },
        { id: 2, text: "PONG", link: "/pong" },
        { id: 3, text: "ASTEROIDS", link: "/asteroids" },
        { id: 4, text: "PACMAN", link: "/pacman" },
        { id: 5, text: "SPACE-INVADERS", link: "/space-invaders" },
        { id: 6, text: "FLAPPY-BIRD", link: "/flappy-bird" },
      ],
      pong: false,
      asteroids: false,
      pacman: false,
      invaders: false,
      bird: false,
    };
  },
  mounted() {
    document.addEventListener("keydown", this.nextItem);

    if (localStorage.pong) {
      $("#pong").addClass("enabled");
    }
    if (localStorage.asteroids) {
      $("#asteroids").addClass("enabled");
    }
    if (localStorage.pacman) {
      $("#pacman").addClass("enabled");
    }
    if (localStorage.invaders) {
      $("#space-invaders").addClass("enabled");
    }
    if (localStorage.bird) {
      $("#flappy-bird").addClass("enabled");
    }
  },
  methods: {
    nextItem(e) {
      if (e.keyCode == 38 && this.currentItem > 1) {
        this.currentItem--;
      } else if (e.keyCode == 40 && this.currentItem < 6) {
        this.currentItem++;
      }
      if (e.keyCode == 13) {
        document.getElementById(this.currentItem).click();
      }
    },
    addClass: function (e) {
      e.target.classList.add("active-item");
    },
    removeClass: function (e) {
      e.target.classList.remove("active-item");
    },
  },
};
</script>