<template>
  <div class="container">
    <div class="top-container">
      <div class="title-container">
        <h1 class="title">SELECT SPOTLIGHT</h1>
      </div>
    </div>

    <div class="options-container">
      <ul class="card-list">
        <li
          v-for='backlight in backlights'
          :key='backlight.id'
          :class='{"active-item": currentItem === backlight.id}'
          @mouseenter="addClass"
          @mouseleave="removeClass"
        >
          <div
            class="option"
            @click="buyItem(backlight.price, backlight.colour)"
          >
            <img
              src="@/assets/images/select.png"
              class="left-arrow"
              alt=""
            >
            <span>{{backlight.text}} X {{backlight.price}}</span>
            <img
              src="@/assets/images/select.png"
              class="right-arrow"
              alt=""
            >
          </div>
        </li>
      </ul>
    </div>

    <div
      class="error-message"
      :class='{"active": errorMessage == true}'
    >
      YOU DO NOT HAVE ENOUGH COINS
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentItem: 1,
      backlights: [
        { id: 1, text: "RED", colour: "rgba(255, 0, 0, 0.2)", price: 5 },
        { id: 2, text: "BLUE", colour: "rgba(0, 0, 255, 0.2)", price: 5 },
        { id: 3, text: "GREEN", colour: "rgba(0, 128, 0, 0.2)", price: 5 },
        { id: 4, text: "YELLOW", colour: "rgba(255, 255, 49, 0.2)", price: 5 },
      ],
      backlight: "white",
      coins: 0,
      errorMessage: false,
    };
  },
  watch: {
    coins(newCoins) {
      localStorage.coins = JSON.stringify(newCoins);
    },
    backlight(newBacklight) {
      localStorage.backlight = JSON.stringify(newBacklight);
    },
  },
  mounted() {
    document.addEventListener("keydown", this.nextItem);

    //Local storage for coin count
    if (localStorage.coins) {
      this.coins = JSON.parse(localStorage.coins);
    }
    if (localStorage.backlight) {
      this.backlight = JSON.parse(localStorage.backlight);
    }
  },
  methods: {
    nextItem(e) {
      if (e.keyCode == 38 && this.currentItem > 1) {
        this.currentItem--;
      } else if (e.keyCode == 40 && this.currentItem < 6) {
        this.currentItem++;
      }
    },
    addClass: function (e) {
      e.target.classList.add("active-item");
    },
    removeClass: function (e) {
      e.target.classList.remove("active-item");
    },
    buyItem: function (price, colour) {
      if (this.coins < price) {
        this.errorMessage = !this.errorMessage;
        setTimeout(() => {
          this.errorMessage = false;
        }, 3000);
      } else {
        this.coins -= price;
        this.backlight = colour;
        this.$router.go();
      }
    },
  },
};
</script>
<style lang="scss">
.error-message {
  justify-content: flex-end;
  color: red;
  width: 100%;
  text-align: center;
  margin: auto;
  font-size: 30px;
  line-height: 30px;
  letter-spacing: 5px;
  text-shadow: 1px 1px white;
  display: none;

  &.active {
    display: block !important;
  }
}
</style>