<template>
  <div class="container">
    <div class="top-container">
      <div class="title-container">
        <h1 class="title">CONTACT</h1>
      </div>
    </div>
    <div class="message-container">
      <p class="nes-balloon from-left nes-pointer">
        I am interested in freelance opportunities - especially with startup projects. Bringing ideas to life! However, if you have other requests or questions, please don't hesitate to contact me using the below form.
      </p>
    </div>
    <div class="contact-wrapper">
      <form
        ref="form"
        @submit.prevent="sendEmail"
      >
        <div class="contact-container">
          <div class="nes-field">
            <label for="name_field">NAME</label>
            <input
              type="text"
              id="name_field"
              name="user_name"
              class="nes-input"
            >
          </div>
          <div class="nes-field">
            <label for="name_field">EMAIL</label>
            <input
              type="text"
              name="user_email"
              id="name_field"
              class="nes-input"
            >
          </div>
        </div>
        <div class="textarea-container">
          <label for="textarea_field">MESSAGE</label>
          <textarea
            id="textarea_field"
            name="message"
            class="nes-textarea"
          ></textarea>
        </div>
      </form>
    </div>

    <div class="cta-button">
      <button
        type="submit"
        value="Send"
        class="nes-btn is-warning"
      >SUBMIT</button>
    </div>
  </div>
</template>

<script>
import emailjs from "@emailjs/browser";
export default {
  methods: {
    sendEmail() {
      emailjs
        .sendForm(
          "gmail",
          "gmail",
          this.$refs.form,
          "user_u9n8flE3Axcib725r39Av"
        )
        .then(
          (result) => {
            console.log("SUCCESS!", result.text);
          },
          (error) => {
            console.log("FAILED...", error.text);
          }
        );
    },
  },
};
</script>

<style lang="scss">
form {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  form {
    flex-direction: row !important;
  }
}
.contact-container {
  position: relative;
  z-index: 99;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .contact-container {
    position: relative;
    z-index: 99;
    max-width: 50%;
    margin: 0 auto;
  }
}

.contact-wrapper {
  display: flex;
  flex-direction: column;
  margin: auto;
}

@media (min-width: 768px) {
  .contact-wrapper {
    flex-direction: row;
    justify-content: flex-end;
  }
}

.cta-button {
  display: flex;
  justify-content: center;
}

.message-container {
  display: none;

  &::after {
    content: "";
    background-image: url("../assets/images/300x300.png");
    background-size: contain;
    background-repeat: no-repeat;
    height: 300px;
    width: 300px;
    position: absolute;
    left: -150px;
    bottom: -250px;
    z-index: -1;
  }
}

@media (min-width: 768px) {
  .message-container {
    position: relative;
    z-index: 99;
    width: 60%;
    margin: 0 auto;
    font-size: 12px;
    display: block;
  }
}

label {
  color: #f3eee7;
  margin: 0;
  font-size: 8px;
}

.nes-field {
  margin: 12px 0;
}

@media (min-width: 768px) {
  .nes-field {
    margin: 50px 0;
  }
}

.textarea-container {
  margin: 0 0 12px 0;
}

@media (min-width: 768px) {
  .textarea-container {
    margin: 50px;
  }
}

.nes-textarea {
  background: #f3eee7;
  height: 85px;
  margin-top: 10px;
  filter: drop-shadow(1px 1px 10px rgb(224, 220, 209));
}

.nes-field .nes-input,
.nes-field .nes-textarea {
  background: #f3eee7;
  margin-top: 10px;
  filter: drop-shadow(1px 1px 10px rgb(224, 220, 209));
}

.nes-balloon {
  background: #f3eee7;
}

.nes-btn {
  font-size: 8px;
}
@media (min-width: 768px) {
  .nes-btn {
    font-size: 16px;
  }
}
</style>